import React, { useState, useEffect } from 'react';
import './App.css';
import { AlunoService } from './service/AlunoService';
import { InputText } from 'primereact/inputtext';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Fieldset } from 'primereact/fieldset';
import { Panel } from 'primereact/panel';
function App() {

	const [aluno, setAluno] = useState(undefined);
	const [naoEncontrado, setNaoEncontrado] = useState(false);
	const [alunos, setAlunos] = useState(undefined);
	const [loading, setLoading] = useState(true);

	const loadAlunos = () => {
		setLoading(true);
		alunoService.getAlunos().then(data => {
			setAlunos(data);
			setLoading(false);
		});

	}

	useEffect(() => {
		loadAlunos();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const alunoService = new AlunoService();
	
	const onLimpar = () => {
		setAluno(undefined);
		setNaoEncontrado(false);
		document.getElementById('username').value = '';
		document.getElementById('username').focus();
	}
	const header = (
		<img style={{ width: "10%" }} alt="Card" src="./assets/images/cemeit_logo.png" />
	);

	const filterAluno = () => {
		// alert("event clicked")
		let nomePesquisa = document.getElementById('username').value.trim();

		const filteredAlunos = alunos.filter((alunoJson) => alunoJson.nome.normalize('NFD').replace(/\p{Diacritic}/gu, "").replace('\ufeff', '') === nomePesquisa.toUpperCase().normalize('NFD').replace(/\p{Diacritic}/gu, ""));
		if (filteredAlunos.length > 0) {
			setAluno(filteredAlunos[0]);
			setNaoEncontrado(false);
		} else {
			setAluno(undefined);
			setNaoEncontrado(true);
		}
	};

	const footer = (
		<div className="flex flex-wrap justify-content-end gap-2">
			<Button label="Pesquisar" icon="pi pi-check" onClick={filterAluno} />
			<Button label="Limpar" icon="pi pi-times" className="p-button-outlined p-button-secondary" onClick={onLimpar} />
		</div>
	);

	const headerTemplate = (options) => {
        const className = `grid` ;

        return (
            
            <div className={className} style={{padding:'0.5rem'}}>
            	<div className="p-1 bg-green-100 col-10 text-center font-bold">
                Multi: {aluno.multi.toFixed(2)}<br/><span class="text-xs">(Pontuação máxima: 3,0)</span>
                </div>
	            <div className="p-1 bg-green-100 col-2">
                    {options.togglerElement}
                </div>
            </div>
        );
    };

	const notaFinalClass = (estudante) => {
		if (estudante.notaFinal < 0)
		{
			return 'text-center text-2xl p-3 border-round-sm bg-red-300 font-bold';
		}
		else {
			return 'text-center text-2xl p-3 border-round-sm bg-green-300 font-bold';
		}
	}
	

	return (

		<div className="App">
			<div className="card flex justify-content-center">
				<Card title="Notas da Avaliação Multibimestral - 3º Bimestre" subTitle="Digite o nome completo do(a) estudante" footer={footer} header={header} className="w-8">
					{loading && (
						<ProgressSpinner />
					)}
					{!loading && (
						<InputText id="username" className='w-full'/>
					)}
				</Card>

			</div>
			{naoEncontrado && (
				<div className='p-4'>
					<Message severity="error" text="Nenhum(a) estudante encontrado(a) com o nome informado." />
				</div>
			)}

			{aluno && (
				<Fieldset legend="Notas do(a) Estudante">
					
				
					<div className="grid align-items-center" >
					    <div className="col-8 md:col-6">
					        <div className="text-center text-2xl p-3 border-round-sm font-bold ">{aluno.nome}</div>
					    </div>
					    <div className="col-4 md:col-2">
					        <div className="text-center p-3 border-round-sm font-bold ">{aluno.turma}</div>
					    </div>
					    <div className="col-12 md:col-4">
					        <div className={notaFinalClass(aluno)}>Nota: {aluno.notaFinal.toFixed(2)}<br/><span class="text-xs">(Pontuação máxima: 4,0)</span></div>
					    </div>
					</div>
					
					<div className="grid">
					    <div className="col-12 md:col-4">
							<Panel headerTemplate={headerTemplate} toggleable collapsed={true}>
								<div className="grid">
									<div className="col-4">
										<div className="text-center text-xs p-1 border-round-sm bg-green-100 font-bold ">Certas: {aluno.questoesCertas}</div>
									</div>
									<div className="col-4">
										<div className="text-center text-xs p-1 border-round-sm bg-green-100 font-bold ">Em branco: {aluno.questoesBranco}</div>
									</div>
									<div className="col-4">
										<div className="text-center text-xs p-1 border-round-sm bg-red-100 font-bold ">Erradas: {aluno.questoesErradas}</div>
									</div>
									<div className="col-6">
										<div className="text-center text-xs p-1 border-round-sm bg-red-100 font-bold ">Fator de Correção: {aluno.fatorCorrecao}</div>
									</div>
									<div className="col-6">
										<div className="text-center text-sm p-1 border-round-sm bg-green-100 font-bold ">Total: {aluno.totalValidas}</div>
									</div>
									
								</div>
							</Panel>
					    </div>
					    
					    {aluno.projetos !== 0 && (
					    <div className="col-12 md:col-4">
					        <div className="text-center p-1 bg-green-100 font-bold ">Projetos: {aluno.projetos.toFixed(2)}<br/><span class="text-xs">(Pontuação máxima: 1,0)</span></div>
					    </div>)}
					    
					    
						<div className="col-12 md:col-2">
					        <div className="text-center p-1 border-round-sm bg-red-100 font-bold ">Disciplinar: {aluno.disciplinar.toFixed(2)}<br/>&nbsp;</div>
					    </div>
					    
					    <div className="col-12 md:col-2">
					        <div className="text-center p-1 border-round-sm bg-red-100 font-bold ">Atrasos: {aluno.atrasos.toFixed(2)}<br/>&nbsp;</div>
					    </div>
					</div>
				</Fieldset>
			)}
		</div>

	);
}

export default App;
